/* Default variables for Mobile design */
:root {
  /* Text */
  --heading-size: 28px;
  --paragraph-size: 16px;
  --heading-color: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.74) 100%);
  --text-color: linear-gradient(135deg, #FFF 0%, rgba(255, 255, 255, 0.74) 100%);
  --radial-text-color: radial-gradient(23.70% 50.00% at 50.10% 50.00%, #FFF 0%, rgba(255, 255, 255, 0.70) 100%);
  --text-font-family: Inter, serif;

  /* Header */
  --header-text-area-width: 400px;
}

/* Changes to variables for Desktop design */
@media (min-width: 850px) {
  :root {
    /* Text */
    --heading-size: 64px;
    --paragraph-size: 22px;

    /* Header */
    --header-text-area-width: 800px;
  }
}

.desktop {
  width: 100%;
  /*height: 5000px;*/
  flex-shrink: 0;
  overflow: clip;
}

.top-bar-section {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background: rgba(11, 2, 15, 0.8);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px 15px rgba(11, 2, 15, 0.8);
  z-index: 99;
}

.logo {
  margin-top: 10px;
  width: calc(192px / 2);
  height: calc(108px / 2);
  position: absolute;
  left: 0px;
}

.menu-bar {
  margin-top: 10px;
  align-items: center;
}

.navbar-curve {
  width: 500px;
  height: 51px;
  border-radius: 16px;
  border: 3px solid #39393980;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  list-style: none;
}

.navbar-text {
  color: #D9D9D9;
  font-family: var(--text-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: none;
  border: none;
  cursor: pointer;
}

.navbar-text:hover {
  color: #afafaf;
}

.sections {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 42px;
}

.header-section {
  display: flex;
  height: 60vh;
  width: 94vw;
  margin-left: 3vw;
  margin-right: 3vw;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-image: url("../../assets/images/Heading card mobile.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 21px;
}

.header-text-area {
  display: flex;
  width: min(90vw, 375px);
  height: 60vh;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  flex-shrink: 0;
}

h1 {
  text-align: center;
  font-family: var(--text-font-family);
  font-size: var(--heading-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
  background: var(--heading-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.line-medium {
  width: 150px;
  height: 2px;
  background: #896D92;
}

.line-large {
  width: 300px;
  height: 2px;
  background: #896D92;
}

.heading-text {
  width: min(80vw, 800px);
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  font-family: var(--text-font-family);
  font-size: var(--paragraph-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-button {
  /* Elevation 2 */
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
  font-size: 16px !important;
}

.menu-button {
  color: #7F32A2 !important;
  width: 32px !important;
  height: 32px !important;
}

.icon-button {
  position: absolute !important;
  right: 16px !important;
}

.chev-icon {
  color: #D9D9D9 !important;
  width: 32px !important;
  height: 32px !important;
}

.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
  margin-top: 21px;
}

.cards {
  display: flex;
  width: 250px;
  height: calc(250px * 3 + 42px * 2);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  flex-shrink: 0;
  margin-top: 42px;
}

.card {
  position: relative;
  display: flex;
  width: 250px;
  min-height: 250px;
  max-height: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16px;
  padding: 3px;
  background: linear-gradient(-45deg, #C853FF, #8A53FF);
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.percentage-text {
  text-align: center;
  padding-left: 5%;
  font-family: var(--text-font-family);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  background: var(--radial-text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-text {
  width: 80%;
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  background: var(--text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-section-text {
  width: min(80vw, 1100px);
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  background: var(--text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 42px;
}

.services-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  width: 94vw;
  margin-left: 3vw;
  margin-right: 3vw;
  padding-top: 42px;
  background-image: url("../../assets/images/heading card mobile reversed.png");
  align-self: stretch;
  transform: rotate(0deg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.service-section-header {
  width: min(80vw, 800px);
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--radial-text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  flex-shrink: 0;
  margin-top: 42px;
}

.service {
  width: 230px;
  height: 410px;
  flex-shrink: 0;
  border-radius: 16px;
  background: rgba(57, 57, 57, 0.66);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  padding: 21px;
}

.service-header {
  width: 50%;
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  background: var(--text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-text {
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  background: var(--text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-line-long {
  width: 150px;
  height: 2px;
  background: #896D92;
}

.service-line-short {
  width: 75px;
  height: 2px;
  background: #896D92;
}

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  width: 96vw;
  margin-top: 42px;
  /*padding-top: 42px;*/
}

.contact-section-header {
  width: min(80vw, 800px);
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--radial-text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-section-text {
  width: min(80vw, 800px);
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  background: var(--text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-form {
  position: relative;
  display: flex;
  width: min(90vw, 1000px);
  min-height: 750px;
  max-height: 750px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  border-radius: 32px;
  border: 3px solid rgba(224, 158, 255, 0.11);
  background: rgba(224, 158, 255, 0.03);
  backdrop-filter: blur(20px);
}

.contact-form-wrap {
  width: min(90vw, 1000px);
  min-height: 750px;
  max-height: 750px;
  border-radius: 32px;
  background: url("../../assets/images/bg_form_v2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.contact-text-input {
  width: 200px;
}

.contact-message-input {
  width: min(85vw, 300px);
}

.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  width: 96vw;
  height: 250px;
  margin-top: 42px;
  padding-top: 42px;
}

.footer-text {
  width: min(80vw, 800px);
  text-align: center;
  font-family: var(--text-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  background: var(--text-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 42px;
  flex-direction: row;
}

.footer-icon-image {
  width: 32px;
  height: 32px;
}

/* Changes to variables for Desktop design */
@media (min-width: 850px) {

  .top-bar-section {
    width: 100%;
    height: 75px;
  }

  .header-section {
    background-image: url("../../assets/images/heading_card.png");
  }

  .logo {
    width: calc(192px / 2 * 1.5);
    height: calc(108px / 2 * 1.5);
  }

  .line-medium {
    width: 300px;
    height: 4px;
  }

  .line-large {
    width: 600px;
    height: 4px;
  }

  .header-section {
    height: 800px;
  }

  .header-text-area {
    width: 800px;
    height: 560px;
  }

  .cards-section {
    margin-top: 42px;
  }

  .cards {
    margin-top: 42px;
    height: min(25vw, 350px);
    width: calc(350px * 3 + 42px * 4);
    align-items: center;
    gap: 100px;
    flex-shrink: 0;
    flex-direction: row;
  }

  .card {
    width: min(25vw, 350px);
    min-height: min(25vw, 350px);
    max-height: min(25vw, 350px);
    gap: 42px;
  }

  .percentage-text {
    font-size: min(5vw, 72px);
  }

  .card-text {
    font-size: min(1.75vw, 24px);
  }

  .card-section-text {
    font-size: min(1.75vw, 24px);
  }

  .service-section-header {
    font-size: 48px;
  }

  .services-section {
    background-image: url("../../assets/images/gradient_reversed.png");
  }

  .services {
    flex-direction: row;
    gap: 100px;
  }

  .service {
    width: 275px;
    height: 450px;
  }

  .service-header {
    width: 75%;
    font-size: 32px;
  }

  .service-text {
    font-size: 20px;
  }

  .service-line-long {
    width: 200px;
  }

  .service-line-short {
    width: 100px;
  }

  .contact-message-input {
    width: 600px;
  }
}