/*:root {*/
/*  --headings-multiplier: 1;*/
/*}*/

/*@media (min-width: 1000px) {*/
/*  :root {*/
/*    --headings-multiplier: 3 / 2;*/
/*  }*/
/*}*/

/*.heading-medium {*/
/*  font-size: calc(2rem * var(--headings-multiplier))*/
/*}*/

/*.heading-large {*/
/*  font-size: calc( 16px + (24 - 16) * (100vw - 400px) / (800 - 400) )*/
/*}*/

.App {
  background: #0B020F;
}